import { ThreePoints, ThreePointsMeta } from '@fl-three-editor/types/vo';
import { updateFlCubeObject3d } from '@fl-three-editor/views/task-three/fl-cube-model';
import { Group } from 'three';
import { MathUtil } from './math-util';

const _interpolation = (
  frameNo: string,
  pointsMeta: { [frameNo: string]: ThreePointsMeta },
  points: { [frameNo: string]: ThreePoints },
  newPoints: ThreePoints,
  onCopy: (frameNo: string, copyPoints: ThreePoints) => void,
  onUpdate: (frameNo: string, updatedPoints: ThreePoints) => void
): void => {
  const changedFrameNo = frameNo;
  const { updateBaseFrame, copyTargets, updateTargets } = Object.keys(points)
    .sort()
    .reduce<{
      lower: boolean;
      end: boolean;
      updateBaseFrame: string;
      prevFrameNo: number;
      copyTargets: string[];
      updateTargets: string[];
    }>(
      (prev, frameNo) => {
        if (prev.end) {
          return prev;
        }
        prev.lower = frameNo < changedFrameNo;
        if (changedFrameNo === frameNo) {
          prev.prevFrameNo = parseInt(frameNo);
          return prev;
        }

        const intFrameNo = parseInt(frameNo);
        if (
          (prev.prevFrameNo !== -1 && prev.prevFrameNo + 1 !== intFrameNo) ||
          !pointsMeta[frameNo].autogenerated
        ) {
          if (prev.lower) {
            prev.prevFrameNo = -1;
            prev.updateTargets.length = 0;
            prev.updateBaseFrame = frameNo;
          } else {
            prev.end = true;
          }
          return prev;
        }
        if (prev.lower) {
          prev.updateTargets.push(frameNo);
        } else {
          prev.copyTargets.push(frameNo);
        }
        prev.prevFrameNo = intFrameNo;
        return prev;
      },
      {
        lower: true,
        end: false,
        prevFrameNo: -1,
        updateBaseFrame: '',
        copyTargets: [],
        updateTargets: [],
      }
    );
  copyTargets.forEach((frameNo) => {
    onCopy(frameNo, newPoints);
  });

  const updatedFrameCount = updateTargets.length;
  if (updatedFrameCount > 0) {
    const basePoints = points[updateBaseFrame];
    // calc diffs
    const diffPoints = basePoints.map(
      (value, index) => value - newPoints[index]
    );
    // to each frame amount
    const frameAmountPoints = diffPoints.map((value) =>
      MathUtil.round(value !== 0 ? value / updatedFrameCount : 0)
    );
    updateTargets.reduce((prev, frameNo) => {
      // add amount
      const added = prev.map(
        (value, index) => value - frameAmountPoints[index]
      ) as ThreePoints;
      onUpdate(frameNo, added);
      return added;
    }, basePoints);
  }
};

export const InterpolationUtil = {
  interpolation: (
    frameNo: string,
    pointsMeta: { [frameNo: string]: ThreePointsMeta },
    points: { [frameNo: string]: ThreePoints },
    newPoints: ThreePoints
  ): void => {
    _interpolation(
      frameNo,
      pointsMeta,
      points,
      newPoints,
      (frameNo, copyPoints) => {
        points[frameNo] = copyPoints;
      },
      (frameNo, updatedPoints) => {
        points[frameNo] = updatedPoints;
      }
    );
  },
  interpolation3D: (
    frameNo: string,
    pointsMeta: { [frameNo: string]: ThreePointsMeta },
    framesPoints: {
      [frameNo: string]: ThreePoints;
    },
    framesObject: {
      [frameNo: string]: Group;
    },
    newPoints: ThreePoints
  ): void => {
    _interpolation(
      frameNo,
      pointsMeta,
      framesPoints,
      newPoints,
      (frameNo, copyPoints) => {
        framesPoints[frameNo] = copyPoints;
      },
      (frameNo, updatedPoints) => {
        framesPoints[frameNo] = updatedPoints;
        updateFlCubeObject3d(framesObject[frameNo], updatedPoints);
      }
    );
  },
};
