import Paper from '@mui/material/Paper';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FrameButton, { FRAME_BUTTON_WIDTH, Mark } from './frame-bar-button';
import { TaskAnnotationVO } from '@fl-three-editor/types/vo';
import { TASK_SIDEBAR_WIDTH } from '../style-const';
import { FormatUtil } from '@fl-three-editor/utils/format-util';

const FRAME_BUTTONS_ROOT_LEFT_PADDING = 4;
const FRAME_BUTTONS_ROOT_RIGHT_PADDING = 4;

const useStyles = makeStyles(() =>
  createStyles({
    frameButtonsRoot: {
      paddingLeft: FRAME_BUTTONS_ROOT_LEFT_PADDING,
      paddingRight: FRAME_BUTTONS_ROOT_RIGHT_PADDING,
      backgroundColor: 'rgb(239, 239, 239)',
    },
    frameButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);

type Props = {
  windowWidth: number;
  totalFrames: number;
  currentFrame: number;
  selectedTaskAnnotations: TaskAnnotationVO[] | undefined;
  hasAnnotation: (frameNo: string) => boolean;
  onClickFrameButton: (frame: number) => void;
  onDoubleClickFrameButton: (frame: number) => void;
};

const FrameBar: React.FC<Props> = ({
  windowWidth,
  totalFrames,
  currentFrame,
  selectedTaskAnnotations,
  hasAnnotation,
  onClickFrameButton,
  onDoubleClickFrameButton,
}) => {
  const styles = useStyles();

  const frameButtonProps = React.useCallback(
    (frame: number) => {
      const frameNo = FormatUtil.number2FrameNo(frame);
      let color = 'gray';
      let hasCircle = false;
      const outlined = false;
      let mark: Mark | undefined = undefined;
      if (hasAnnotation(frameNo)) {
        color = 'lightBlue';
      }

      if (selectedTaskAnnotations && selectedTaskAnnotations.length === 1) {
        const taskAnnotation = selectedTaskAnnotations[0];
        const points = taskAnnotation.points;
        const pointsMeta = taskAnnotation.pointsMeta;
        if (points[frameNo] && pointsMeta[frameNo]) {
          color = 'blue';
          hasCircle = !pointsMeta[frameNo].autogenerated;
          mark = hasCircle ? 'Circle' : undefined;
        }
      }
      return { color, outlined, mark };
    },
    [hasAnnotation, selectedTaskAnnotations]
  );

  const frameButtons = React.useMemo(() => {
    const contentWidth =
      windowWidth -
      TASK_SIDEBAR_WIDTH -
      FRAME_BUTTONS_ROOT_LEFT_PADDING -
      FRAME_BUTTONS_ROOT_RIGHT_PADDING;
    const maxFrameButtonCount = Math.min(
      totalFrames,
      Math.floor(contentWidth / FRAME_BUTTON_WIDTH)
    );
    const currentPage = Math.floor((currentFrame - 1) / maxFrameButtonCount);
    let startIndex = currentPage * maxFrameButtonCount + 1;
    let lastIndex = (currentPage + 1) * maxFrameButtonCount + 1;
    if (lastIndex > totalFrames) {
      lastIndex = totalFrames + 1;
      startIndex = lastIndex - maxFrameButtonCount;
    }
    const buttons = [];
    for (let index = startIndex, key = 0; index < lastIndex; index++, key++) {
      const { color, outlined, mark } = frameButtonProps(index);
      const isActive = currentFrame === index;
      buttons.push(
        <FrameButton
          key={key}
          frameIndex={index}
          color={color}
          isActive={isActive}
          onClick={onClickFrameButton}
          onDoubleClick={onDoubleClickFrameButton}
          outlined={outlined}
          mark={mark}
        />
      );
    }
    return buttons;
  }, [
    frameButtonProps,
    onClickFrameButton,
    onDoubleClickFrameButton,
    currentFrame,
    totalFrames,
    windowWidth,
  ]);
  return (
    <Paper elevation={1}>
      <div className={styles.frameButtonsRoot}>
        <div>
          <div className={styles.frameButtonsContainer}>{frameButtons}</div>
        </div>
      </div>
    </Paper>
  );
};

export default FrameBar;
